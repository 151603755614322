<template>
  <!--头部-->
  <div class="header-box">
    <div class="header-content">
      <div class="img_logo">2022年清华大学特殊类型招生考试准考证下载网站</div>

      <img
        src="../../assets/images/logout.png"
        class="logout"
        @click="loginOut()"
      />
      <div class="username">{{ username }}</div>
      <img src="../../assets/images/user.png" class="img_user" />
    </div>
  </div>
</template>

<script>
import { loginOut } from "r/index/login.js";
export default {
  name: "Header",
  data() {
    return {
      username: window.localStorage.getItem("username")
    };
  },
  methods: {
    async loginOut() {
      const confirmResult = await this.$confirm(`确认退出？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning"
      }).catch(err => err);
      if (confirmResult !== "confirm")
        return this.$message.info("您取消了退出");

      const { data: res } = await loginOut({});
      if (res.code !== 200) return this.$message.error(res.message);
      // 清除本地的 token
      window.localStorage.clear();
      // 使用编程式导航跳转到登录页面
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped lang="scss">
.header-box {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #601b9b;
  color: #ffffff;
  box-shadow: 0px 2px 6px 0px #d5d5d5;

  .header-content {
    max-width: 1400px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    padding: 0 70px;
    .img_logo {
      float: left;
      font-size: 20px;
      font-family: "微软雅黑";
      font-weight: bold;
    }
    .img_user {
      width: 34px;
      height: 34px;
      background-color: #d8d8d8;
      border-radius: 50%;
      float: right;
      margin: 8px 0 0 0;
    }
    .logout {
      width: 34px;
      height: 34px;
      background-color: #d8d8d8;
      border-radius: 50%;
      float: right;
      margin: 8px 0 0 20px;
    }
    .username {
      float: right;
      margin: 0 20px 0 12px;
    }
    .el-dropdown {
      float: right;
    }
  }
}
</style>

<template>
  <el-container>
    <el-header height="auto"><Header></Header></el-header>
    <el-container>
      <router-view />
    </el-container>
  </el-container>
</template>
<script>
import Header from "c/index/Header";
export default {
  name: "index-index",
  components: {
    Header
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">
.el-container {
  height: 100%;
  overflow: hidden;
  min-width: 934px;

  .el-header {
    padding: 0;
  }
  > .el-container {
    width: 100%;
  }
}
</style>

import { request } from "./network";

// 登录
export function login(data) {
  return request({
    method: "post",
    url: "/web/zkz/login",
    data
  });
}

// 登录
export function loginOut(data) {
  return request({
    method: "post",
    url: "/web/zkz/loginOut",
    data
  });
}
